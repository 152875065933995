/** @format */

.dashboard-title-container {
  display: flex;
  justify-content: space-around;
  padding: 1rem;
  border-bottom: 1px solid #222;
  margin-bottom: 1rem;
}

.dashboard-body-container {
  display: flex;
  flex-direction: row;
}

.dbc-navigation {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: sticky;
  width: 20%;
  /* height: 100vh; */
  /* border-right: 1px solid #222; */
}

.dbc-nav-links-container {
  position: absolute;
}

.dbc-nav-link {
  margin-bottom: 1rem;
}

.dbc-content {
  width: 70%;
  /* border-left: 1px solid #222; */
  margin-left: 2rem;
  padding-left: 2rem;
  box-shadow: inset 0 0 10px lightgray;
}
