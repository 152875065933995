/** @format */

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 1rem;
  /* border: 1px solid red; */
  height: 100vh;
}

.form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1rem;
}

.form-container > input,
button {
  margin: 0.5rem;
}
