/** @format */
table {
  border-spacing: 5px 1rem;
}

tr {
  margin: 0.25rem 0 0.25rem 0;
  text-align: center;
}

td {
  border: 1px solid #222;
  min-width: 8rem;
  padding: 0 1rem 0 1rem;
}

thead {
  background-color: #222;
  color: #fff;
}
