/** @format */

.cage-part-container {
  display: flex;
  flex-direction: column;
}

.cage-part-title {
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.cage-part-sections {
  display: flex;
  justify-content: space-around;
}

.cage-part-image {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;
  /* height: 400px; */
  border: 1px solid #222;
  /* margin-right: 3rem; */
}

.cage-part-image > img {
  max-width: 150px;
  max-height: 150;
}

.cage-part-regular-screen {
  /* width: 30%;
  height: 400px;
  border: 1px solid #222;
  margin-right: 3rem; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cage-part-altScreens-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  /* flex-direction: column; */
  width: 60%;
  /* height: 400px; */
  border: 1px solid #222;
}

.cage-part-altScreen-title {
  width: 100%;
}

.cage-part-altScreen-item {
  width: 45%;
  border: 1px solid blue;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
